import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These are the instructions for setting up a host by hand.
We also have `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/ssh/hosts"
      }}>{`an installation script`}</a>{`,
which is the preferred setup method.`}</p>
    <h3>{`Step 1. Run all steps as root`}</h3>
    <p>{`Now let's set up your host. You'll run the entire Host Configuration setup as the `}<inlineCode parentName="p">{`root`}</inlineCode>{` user:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo su
`}</code></pre>
    <h3>{`Step 2. Install `}<inlineCode parentName="h3">{`step`}</inlineCode>{` CLI tool`}</h3>
    <CodeBlock language="shell-session" copyText="curl -L -o step https://dl.step.sm/s3/cli/docs-ssh-host-step-by-step/step_latest_linux_amd64 && install -m 0755 -t /usr/bin step" mdxType="CodeBlock">
      {`$ curl -L -o step https://dl.step.sm/s3/cli/docs-ssh-host-step-by-step/step_latest_linux_amd64
$ install -m 0755 -t /usr/bin step`}
    </CodeBlock>
    <h3>{`Step 3. Install the `}<inlineCode parentName="h3">{`step-ssh`}</inlineCode>{` utilities`}</h3>
    <p>{`This step will install modules and services.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Install on Ubuntu & Debian (DEB package)`}</strong></p>
        <CodeBlock language="shell-session" copyText="curl -LO https://dl.step.sm/s3/ssh/docs-ssh-host-step-by-step/step-ssh_latest_amd64.deb && dpkg -i step-ssh_latest_amd64.deb" mdxType="CodeBlock">
          {`$ curl -LO https://dl.step.sm/s3/ssh/docs-ssh-host-step-by-step/step-ssh_latest_amd64.deb\n$ dpkg -i step-ssh_latest_amd64.deb`}
        </CodeBlock>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Install on CentOS or Amazon Linux 2 (RPM package)`}</strong></p>
        <CodeBlock language="shell-session" copyText={`curl -LO "https://dl.step.sm/s3/ssh/docs-ssh-host-step-by-step/step-ssh_latest_x86_64.rpm" && yum -y install step-ssh_latest_x86_64.rpm`} mdxType="CodeBlock">
          {`$ curl -LO "https://dl.step.sm/s3/ssh/docs-ssh-host-step-by-step/step-ssh_latest_x86_64.rpm"\n$ yum -y install step-ssh_latest_x86_64.rpm`}
        </CodeBlock>
      </li>
    </ul>
    <h3>{`Step 4. Configure `}<inlineCode parentName="h3">{`step`}</inlineCode>{` to connect to your CA`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca bootstrap --team="[your smallstep team ID]"
`}</code></pre>
    <h3>{`Step 5. Get an SSH host certificate`}</h3>
    <p>{`Remember the enrollment token you got when you signed up? You'll need it now. If you downloaded it, the file is called `}<inlineCode parentName="p">{`enrollment_token`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`_`}</inlineCode>{`👇 This leading space will (usually) keep the token out of your shell's history.`}</p>
    <CodeBlock language="shell-session" copyText={` export enrollment_token="[your enrollment token]" && export hostname="[your hostname]"`} mdxType="CodeBlock">
      {`$  export enrollment_token="[your enrollment token]"
$ export hostname="[your hostname]"`}
    </CodeBlock>
    <p>{`The `}<inlineCode parentName="p">{`hostname`}</inlineCode>{` is your host's canonical hostname or IP. This will be the name clients use to SSH to this host.`}</p>
    <h4>{`For hosts with a single hostname`}</h4>
    <p>{`Run the following to issue a certificate for your host:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ssh certificate $hostname /etc/ssh/ssh_host_ecdsa_key.pub \\
    --host --sign --provisioner "Service Account" --token $enrollment_token
`}</code></pre>
    <h4>{`If a host has multiple hostnames...`}</h4>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` When a host has multiple hostnames, your users will only be able to `}<inlineCode parentName="p">{`ssh`}</inlineCode>{` to the canonical `}<inlineCode parentName="p">{`$hostname`}</inlineCode>{`, as shown by the `}<inlineCode parentName="p">{`step ssh hosts`}</inlineCode>{` command.`}</p>
    </blockquote>
    <p>{`If you need multiple hostnames in your host certificate (e.g., public and private hostnames, or a hostname and an IP address), you can pass each of them to `}<inlineCode parentName="p">{`step ssh certificate`}</inlineCode>{` via the `}<inlineCode parentName="p">{`--principal`}</inlineCode>{` flag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ssh certificate $hostname /etc/ssh/ssh_host_ecdsa_key.pub \\
    --host --sign --provisioner "Service Account" --token $enrollment_token \\
    --principal $hostname --principal 10.0.0.42
`}</code></pre>
    <p>{`When multiple hostnames are needed, the canonical `}<inlineCode parentName="p">{`$hostname`}</inlineCode>{` must be passed twice: Once to establish the certificate's Key ID, and again explicitly as its first Principal.`}</p>
    <h3>{`Step 6. Configure SSHD to use certificate authentication`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ssh config --host --set Certificate=ssh_host_ecdsa_key-cert.pub \\
                         --set Key=ssh_host_ecdsa_key
`}</code></pre>
    <p>{`This command will add a few lines of configuration to the end of your `}<inlineCode parentName="p">{`/etc/ssh/sshd_config`}</inlineCode>{` to enable certificate authentication. These lines are annotated with a comment that says `}<inlineCode parentName="p">{`# autogenerated by step @ <timestamp>`}</inlineCode>{` so you can identify them later if you need to modify or revert these changes.`}</p>
    <h3>{`Step 7. Activate PAM/NSS Modules & HUP SSHD`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step-ssh activate "$hostname"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`step-ssh activate`}</inlineCode>{` command will leverage a short-lived identity certificate to authenticate itself to the host inventory.`}</p>
    <h3>{`Step 8. Register the host and add tags(s)`}</h3>
    <p>{`This command will leverage the host identity certificate to authenticate itself to the host inventory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step-ssh-ctl register --hostname "$hostname"
`}</code></pre>
    <h4>{`Registering a host with host tags`}</h4>
    <p>{`For access control in multi-user environments, `}<a parentName="p" {...{
        "href": "#step-0-understanding-host-tags"
      }}>{`host tags`}</a>{` can be assigned via the `}<inlineCode parentName="p">{`--tag`}</inlineCode>{` flag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step-ssh-ctl register --tag <key=value> --tag <role=web> --hostname "$hostname"
`}</code></pre>
    <p>{`It is possible to rerun `}<inlineCode parentName="p">{`step-ssh-ctl register`}</inlineCode>{` multiple times, to rename the host, replace its tags, or change the bastion settings. `}<strong parentName="p">{`Note: This command replaces all existing tags and bastion settings for a host.`}</strong></p>
    <h4>{`Registering a bastion host (jump boxes)`}</h4>
    <p>{`If the host you're registering is a bastion, add the `}<inlineCode parentName="p">{`--is-bastion`}</inlineCode>{` flag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step-ssh-ctl register --hostname "$hostname" --is-bastion
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` Your bastion host will need the `}<inlineCode parentName="p">{`nc`}</inlineCode>{` command installed. Our bastion host support uses `}<inlineCode parentName="p">{`nc`}</inlineCode>{` (along with the `}<inlineCode parentName="p">{`ProxyCommand`}</inlineCode>{` directive) because it's widely compatible with older SSHD servers.`}</p>
    <h4>{`Registering a host behind a bastion`}</h4>
    <p>{`If the host you're registering is `}<em parentName="p">{`behind`}</em>{` a bastion, add the `}<inlineCode parentName="p">{`--bastion`}</inlineCode>{` flag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step-ssh-ctl register --hostname "$hostname" --bastion "[bastion hostname]"
`}</code></pre>
    <h3>{`Step 9. Test your installation`}</h3>
    <p>{`Before you sign out of your `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` session, test your installation by logging in and running `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` in a separate session.`}</p>
    <p><strong parentName="p">{`This step is especially important if you have made any non-standard changes to your PAM or NSS stacks.`}</strong></p>
    <p>{`Now sign in at `}<inlineCode parentName="p">{`https://smallstep.com/app/[Team ID]`}</inlineCode></p>
    <p>{`You should see your host listed under the "`}<strong parentName="p">{`Hosts`}</strong>{`" tab.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      